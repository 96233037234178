import React, { useState, useCallback, useEffect } from "react";
import log from "../components/utils/logger";
import { OptionCreator, ActionCreator } from "../components";
import { getConfig } from "../config/config";
import { useAuth0 } from "@auth0/auth0-react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function locations() {
	const { user, getAccessTokenSilently } = useAuth0();

	const [locs, setLocs] = useState([]);

	const apiUrl = getConfig().apiUrl;

	const getLocs = useCallback(async () => {
		try {
			const response = await fetch(apiUrl + "/locations", {
				method: "GET",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					Authorization: "Bearer " + (await getAccessTokenSilently()),
					"X-Admin": user.sub.replace("auth0|", ""),
				},
			});

			if (response.ok) {
				log("Locations fetched successfully.");
				const data = await response.json();
				setLocs(data.content);
			} else {
				const data = await response.json();
				toast.error("Error fetching locations. " + data.details, {
					position: "bottom-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: true,
					progress: undefined,
					theme: "colored",
				});
				log("Error fetching locations. " + data.details);
			}
		} catch (e) {
			toast.error("Error fetching locations. " + e.message, {
				position: "bottom-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: true,
				progress: undefined,
				theme: "colored",
			});
			console.log(e.message);
		}
	}, [apiUrl]);

	useEffect(() => {
		getLocs();
	}, [getLocs]);

	return (
		<div>
			<div className="profile-header mt-4">
				<h2>
					<button
						className="refresh-button"
						onClick={() => getLocs()}
					>
						<i className="bi bi-arrow-clockwise"></i>
					</button>
					Locations
				</h2>
			</div>
			<div className="row g-4 pb-5 pt-3 row-cols-1 row-cols-lg">
				<OptionCreator
					title={"Create location"}
					description={"Create a new location"}
					action={"add-loc"}
					icon={"house-add"}
					btnMessage={"Add"}
					key={"add-loc"}
				/>
			</div>
			<div className="table-responsive">
				<table className="table table-striped" id="locs">
					<thead>
						<tr>
							<th>Location name</th>
							<th>Administrator</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						{locs.map((loc) => (
							<tr key={loc.id} id={loc.id}>
								<td id={loc.id + "_name"}>{loc.name}</td>
								<td id={loc.id + "_admin"}>{loc.user.name}</td>
								<td>
									<ActionCreator
										title={"Edit location"}
										action={"edit-loc"}
										icon={"pen"}
										key={"edit-loc-" + loc.id}
										id={loc.id}
									/>
									<ActionCreator
										title={"Delete location"}
										action={"delete-loc"}
										icon={"trash"}
										key={"delete-loc-" + loc.id}
										id={loc.id}
									/>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<ToastContainer
				position="bottom-right"
				autoClose={3000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				theme="colored"
			/>
		</div>
	);
}

export default locations;
