import React, { useState, useCallback, useEffect } from "react";
import log from "../components/utils/logger";
import { OptionCreator, ActionCreator } from "../components";
import { getConfig } from "../config/config";
import { useAuth0 } from "@auth0/auth0-react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function users() {
	const { getAccessTokenSilently, user } = useAuth0();

	const [users, setUsers] = useState([]);
	const [belongsTo, setBelongsTo] = useState("empty");
	const [title, setTitle] = useState("empty");
	const [addUser, setAddUser] = useState(false);

	const config = getConfig();
	const apiUrl = config.apiUrl;

	const getUsers = useCallback(async () => {
		let roleToFetch = "";
		if (user["user_type"] === "superadmin") {
			roleToFetch = "admin";
			setBelongsTo("Organization");
			setTitle("Administrators");
			setAddUser(false);
		} else if (user["user_type"] === "admin") {
			roleToFetch = "user";
			setBelongsTo("Location");
			setTitle("Users");
			setAddUser(true);
		}

		try {
			const response = await fetch(apiUrl + "/users/" + roleToFetch, {
				method: "GET",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					Authorization: "Bearer " + (await getAccessTokenSilently()),
					"X-Admin": user.sub.replace("auth0|", ""),
				},
			});

			if (response.ok) {
				log("Users fetched successfully.");
				const data = await response.json();
				setUsers(data.content);
			} else {
				const data = await response.json();
				toast.error("Error fetching users. " + data.details, {
					position: "bottom-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: true,
					progress: undefined,
					theme: "colored",
				});
				log("Error fetching users. " + data.details);
			}
		} catch (e) {
			toast.error("Error fetching users. " + e.message, {
				position: "bottom-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: true,
				progress: undefined,
				theme: "colored",
			});
			console.log(e.message);
		}
	}, [apiUrl]);

	useEffect(() => {
		getUsers();
	}, [getUsers]);

	return (
		<div>
			<div className="profile-header mt-4">
				<h2>
					<button
						className="refresh-button"
						onClick={() => getUsers()}
					>
						<i className="bi bi-arrow-clockwise"></i>
					</button>
					{title}
				</h2>
			</div>
			<div className="row g-4 pb-5 pt-3 row-cols-1 row-cols-lg">
				{addUser ? (
					<OptionCreator
						title={"Create a user"}
						description={"Create a new user"}
						action={"add-user"}
						icon={"person-fill-add"}
						btnMessage={"Add"}
						key={"add-user"}
					/>
				) : (
					""
				)}
			</div>
			<div className="table-responsive">
				<table className="table table-striped" id="users">
					<thead>
						<tr>
							<th>Name</th>
							<th>Email</th>
							<th>{belongsTo}</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						{users.map((user) => (
							<tr key={user.id} id={user.id}>
								<td id={user.id + "_name"}>{user.name}</td>
								<td id={user.id + "_email"}>{user.email}</td>
								<td id={user.id + "_belongs"}>
									{user.belongsTo}
								</td>
								<td>
									<ActionCreator
										title={"Edit user"}
										action={"edit-user"}
										icon={"pen"}
										key={"edit-user-" + user.id}
										id={user.id}
									/>
									{addUser ? (
										<ActionCreator
											title={"Delete user"}
											action={"delete-user"}
											icon={"trash"}
											key={"delete-user-" + user.id}
											id={user.id}
										/>
									) : (
										""
									)}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<ToastContainer
				position="bottom-right"
				autoClose={3000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				theme="colored"
			/>
		</div>
	);
}

export default users;
