// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-y: scroll;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.spinner {
	position: absolute;
	display: flex;
	justify-content: center;
	height: 100vh;
	width: 100vw;
	background-color: white;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;CACC,SAAS;CACT,UAAU;CACV;;YAEW;CACX,mCAAmC;CACnC,kCAAkC;CAClC,kBAAkB;AACnB;;AAEA;CACC;WACU;AACX;;AAEA;CACC,kBAAkB;CAClB,aAAa;CACb,uBAAuB;CACvB,aAAa;CACb,YAAY;CACZ,uBAAuB;CACvB,MAAM;CACN,SAAS;CACT,OAAO;CACP,QAAQ;AACT","sourcesContent":["body {\n\tmargin: 0;\n\tpadding: 0;\n\tfont-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n\t\t\"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n\t\tsans-serif;\n\t-webkit-font-smoothing: antialiased;\n\t-moz-osx-font-smoothing: grayscale;\n\toverflow-y: scroll;\n}\n\ncode {\n\tfont-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n\t\tmonospace;\n}\n\n.spinner {\n\tposition: absolute;\n\tdisplay: flex;\n\tjustify-content: center;\n\theight: 100vh;\n\twidth: 100vw;\n\tbackground-color: white;\n\ttop: 0;\n\tbottom: 0;\n\tleft: 0;\n\tright: 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
