import React, { Fragment } from "react";

import { Hero } from "../components";

const Home = () => (
	<Fragment>
		<Hero />
	</Fragment>
);

export default Home;
