import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Alert } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { FloatingLabel } from "react-bootstrap";
import log from "../utils/logger";
import { getConfig } from "../../config/config";
import PropTypes from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";

function EditItem(props) {
	const { getAccessTokenSilently } = useAuth0();

	const apiUrl = getConfig().apiUrl;

	const [errorMsg, setErrorMsg] = useState("empty");
	const [submitButton, setSubmitButton] = useState("Submit");

	const [itemColor, setItemColor] = useState("empty");
	const [itemName, setItemName] = useState("empty");
	const [itemQuantity, setItemQuantity] = useState("empty");
	const [itemSizeX, setItemSizeX] = useState("empty");
	const [itemSizeY, setItemSizeY] = useState("empty");

	const id = props.id;

	function validateItemName() {
		let name = document.getElementById("itemName").value;

		name.length > 2 ? setItemName(true) : setItemName(false);

		name.length === 0 ? setItemName("empty") : setItemName(true);
	}

	function validateColorName() {
		let color = document.getElementById("itemColor").value;

		color.length > 2 ? setItemColor(true) : setItemColor(false);

		color.length === 0 ? setItemColor("empty") : setItemColor(true);
	}

	async function ApiEditOrg() {
		setSubmitButton(
			<div className="spinner-border spinner-border-sm" role="status">
				<span className="sr-only"></span>
			</div>,
		);

		if (
			(itemColor !== "empty" && itemColor !== "") ||
			(itemName !== "empty" && itemName !== "") ||
			(itemQuantity !== "empty" && itemQuantity !== "") ||
			(itemSizeX !== "empty" && itemSizeX !== "") ||
			(itemSizeY !== "empty" && itemSizeY !== "")
		) {
			try {
				let newItemName,
					newItemColor,
					newItemQuantity,
					newItemSizeX,
					newItemSizeY;

				if (itemName === "empty" || itemName === "") {
					newItemName = document.getElementById(
						id + "_name",
					).innerText;
				} else {
					newItemName = document.getElementById("itemName").value;
				}

				if (itemColor === "empty" || itemColor === "") {
					newItemColor = document.getElementById(
						id + "_color",
					).innerText;
				} else {
					newItemColor = document.getElementById("itemColor").value;
				}

				if (itemQuantity === "empty") {
					const availability = document.getElementById(
						id + "_availability",
					).innerText;
					newItemQuantity = availability.split("/")[1];
				} else {
					newItemQuantity =
						document.getElementById("itemQuantity").value;
				}

				if (itemSizeX === "empty") {
					newItemSizeX = document.getElementById(
						id + "_sizeX",
					).innerText;
				} else {
					newItemSizeX = document.getElementById("itemSizeX").value;
				}

				if (itemSizeY === "empty") {
					newItemSizeY = document.getElementById(
						id + "_sizeY",
					).innerText;
				} else {
					newItemSizeY = document.getElementById("itemSizeY").value;
				}

				const response = await fetch(apiUrl + "/inventory", {
					method: "PATCH",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
						Authorization:
							"Bearer " + (await getAccessTokenSilently()),
					},
					body: JSON.stringify({
						id: id,
						name: newItemName,
						color: newItemColor,
						quantity: newItemQuantity,
						sizeX: newItemSizeX,
						sizeY: newItemSizeY,
					}),
				});

				if (response.ok) {
					toast.success(
						"Item edited successfully, you may now refresh the page",
						{
							position: "bottom-right",
							autoClose: 3000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: false,
							draggable: true,
							progress: undefined,
							theme: "colored",
						},
					);
					log("Item edited successfully.");
					setErrorMsg("empty");
					setItemName("empty");
					setItemColor("empty");
					setItemQuantity("empty");
					setItemSizeX("empty");
					setItemSizeY("empty");
					props.onHide(false);
					setSubmitButton("Submit");
				} else {
					const data = await response.json();
					toast.error(data.details, {
						position: "bottom-right",
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: false,
						draggable: true,
						progress: undefined,
						theme: "colored",
					});
					log(data.details);
					setSubmitButton("Submit");
					setErrorMsg(data.details);
				}
			} catch (e) {
				toast.error(e.message, {
					position: "bottom-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: true,
					progress: undefined,
					theme: "colored",
				});
				setErrorMsg("empty");
				props.onHide(false);
				setSubmitButton("Submit");
			}
		} else {
			setSubmitButton("Submit");
			setErrorMsg("At least one field must be filled");
		}
	}

	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			onExiting={() => {
				setErrorMsg("empty");
				setSubmitButton("Submit");
				setItemName("empty");
				setItemColor("empty");
				setItemQuantity("empty");
				setItemSizeX("empty");
				setItemSizeY("empty");
			}}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					{props.title}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<FloatingLabel label="Name" htmlFor="itemName">
					<Form.Control
						type="text"
						className="form-control mb-3"
						id="itemName"
						required
						onChange={validateItemName}
					/>
				</FloatingLabel>
				{itemName === "empty" || itemName === true ? (
					""
				) : (
					<Alert key="itemName" variant="danger">
						Enter a name for the item
					</Alert>
				)}
				<FloatingLabel label="Color" htmlFor="itemColor">
					<Form.Control
						type="text"
						className="form-control mb-3"
						id="itemColor"
						required
						onChange={validateColorName}
					/>
				</FloatingLabel>
				{itemColor === "empty" || itemColor === true ? (
					""
				) : (
					<Alert key="color" variant="danger">
						Enter a color for the item
					</Alert>
				)}
				<FloatingLabel label="Quantity" htmlFor="itemQuantity">
					<Form.Control
						type="number"
						className="form-control mb-3"
						id="itemQuantity"
						required
						onChange={(e) => {
							const value = e.target.value;
							if (value > 0) {
								setItemQuantity(value);
							} else {
								e.target.value = 0;
							}
						}}
					/>
				</FloatingLabel>
				<FloatingLabel label="Size in axis X (cm)" htmlFor="itemSizeX">
					<Form.Control
						type="number"
						className="form-control mb-3"
						id="itemSizeX"
						required
						onChange={(e) => {
							const value = e.target.value;
							if (value > 0) {
								setItemSizeX(value);
							} else {
								e.target.value = 0;
							}
						}}
					/>
				</FloatingLabel>
				<FloatingLabel label="Size in axis Y (cm)" htmlFor="itemSizeY">
					<Form.Control
						type="number"
						className="form-control mb-3"
						id="itemSizeY"
						required
						onChange={(e) => {
							const value = e.target.value;
							if (value > 0) {
								setItemSizeY(value);
							} else {
								e.target.value = 0;
							}
						}}
					/>
				</FloatingLabel>
				{errorMsg === "empty" || errorMsg === true ? (
					""
				) : (
					<Alert key="email" variant="danger">
						{errorMsg}
					</Alert>
				)}
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant="primary"
					onClick={ApiEditOrg}
					disabled={submitButton != "Submit"}
				>
					{submitButton}
				</Button>
				<Button variant="secondary" onClick={props.onHide}>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

EditItem.propTypes = {
	onHide: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
};

export default EditItem;
