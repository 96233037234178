import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from "../../config/config";

const LogoutButton = () => {
	const { logout } = useAuth0();

	return (
		<button
			className="btn btn-danger btn-block"
			onClick={() =>
				logout({
					logoutParams: {
						returnTo: getConfig().url,
					},
				})
			}
		>
			Log Out
		</button>
	);
};

export default LogoutButton;
