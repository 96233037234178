import React, { useState } from "react";
import { Button } from "react-bootstrap";
import "../../App.css";
import { ChangeEmail, ChangePasswd, ChangePicture } from "../profile";
import { AddOrg } from "../orgs";
import { AddLocation } from "../locations";
import { AddUser } from "../users";
import { AddItem } from "../inventory";
import PropTypes from "prop-types";

function OptionCreator(props) {
	const [modalShow, setModalShow] = useState(false);

	let option;
	const title = props.title;
	const description = props.description;
	const action = props.action;
	const icon = "bi bi-" + props.icon;
	const btnMessage = props.btnMessage ? props.btnMessage : "Change";

	if (action === "picture") {
		option = (
			<ChangePicture
				show={modalShow}
				onHide={() => setModalShow(false)}
				title={title}
				key={action}
			/>
		);
	} else if (action === "email") {
		option = (
			<ChangeEmail
				show={modalShow}
				onHide={() => setModalShow(false)}
				title={title}
				key={action}
			/>
		);
	} else if (action === "passwd") {
		option = (
			<ChangePasswd
				show={modalShow}
				onHide={() => setModalShow(false)}
				title={title}
				key={action}
			/>
		);
	} else if (action === "add-org") {
		option = (
			<AddOrg
				show={modalShow}
				onHide={() => setModalShow(false)}
				title={title}
				key={action}
			/>
		);
	} else if (action === "add-user") {
		option = (
			<AddUser
				show={modalShow}
				onHide={() => setModalShow(false)}
				title={title}
				key={action}
			/>
		);
	} else if (action === "add-loc") {
		option = (
			<AddLocation
				show={modalShow}
				onHide={() => setModalShow(false)}
				title={title}
				key={action}
			/>
		);
	} else if (action === "add-item") {
		option = (
			<AddItem
				show={modalShow}
				onHide={() => setModalShow(false)}
				title={title}
				key={action}
			/>
		);
	}

	return (
		<div className="col d-flex align-items-start">
			<div className="icon-square text-dark flex-shrink-0 me-3">
				<i className={icon}></i>
			</div>
			<div>
				<h2>{title}</h2>
				<p>{description}</p>
				<Button variant="primary" onClick={() => setModalShow(true)}>
					{btnMessage}
				</Button>
			</div>
			{option}
		</div>
	);
}

OptionCreator.propTypes = {
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	action: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
	btnMessage: PropTypes.string.notRequired,
};

export default OptionCreator;
