import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Alert } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { FloatingLabel } from "react-bootstrap";
import log from "../utils/logger";
import { getConfig } from "../../config/config";
import PropTypes from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";

function AddItem(props) {
	const { user, getAccessTokenSilently } = useAuth0();

	const apiUrl = getConfig().apiUrl;

	let [errorMsg, setErrorMsg] = useState("empty");
	let [submitButton, setSubmitButton] = useState("Submit");

	let [itemColor, setItemColor] = useState("empty");
	let [itemName, setItemName] = useState("empty");
	let [itemQuantity, setItemQuantity] = useState("empty");
	let [itemSizeX, setItemSizeX] = useState("empty");
	let [itemSizeY, setItemSizeY] = useState("empty");

	function validateItemName() {
		let name = document.getElementById("itemName").value;

		name.length > 2 ? setItemName(true) : setItemName(false);

		name.length === 0 ? setItemName("empty") : setItemName(true);
	}

	function validateColorName() {
		let color = document.getElementById("itemColor").value;

		color.length > 2 ? setItemColor(true) : setItemColor(false);

		color.length === 0 ? setItemColor("empty") : setItemColor(true);
	}

	async function ApiAddItem() {
		setSubmitButton(
			<div className="spinner-border spinner-border-sm" role="status">
				<span className="sr-only"></span>
			</div>,
		);

		if (
			itemColor !== "empty" &&
			itemName !== "empty" &&
			itemQuantity !== "empty" &&
			itemSizeX !== "empty" &&
			itemSizeY !== "empty"
		) {
			try {
				const itemName = document.getElementById("itemName").value;
				const itemColor = document
					.getElementById("itemColor")
					.value.toLowerCase();
				const itemQuantity =
					document.getElementById("itemQuantity").value;
				const itemSizeX = document.getElementById("itemSizeX").value;
				const itemSizeY = document.getElementById("itemSizeY").value;

				const response = await fetch(apiUrl + "/inventory", {
					method: "POST",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
						Authorization:
							"Bearer " + (await getAccessTokenSilently()),
					},
					body: JSON.stringify({
						creator: user.sub.replace("auth0|", ""),
						name: itemName,
						color: itemColor,
						quantity: itemQuantity,
						sizeX: itemSizeX,
						sizeY: itemSizeY,
					}),
				});

				if (response.ok) {
					toast.success(
						"Item added successfully, you may now refresh the page",
						{
							position: "bottom-right",
							autoClose: 3000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: false,
							draggable: true,
							progress: undefined,
							theme: "colored",
						},
					);
					log("Item added successfully.");
					setErrorMsg("empty");
					setItemName("empty");
					setItemColor("empty");
					setItemQuantity("empty");
					setItemSizeX("empty");
					setItemSizeY("empty");
					props.onHide(false);
					setSubmitButton("Submit");
				} else {
					const data = await response.json();
					toast.error(data.details, {
						position: "bottom-right",
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: false,
						draggable: true,
						progress: undefined,
						theme: "colored",
					});
					log(data.details);
					setSubmitButton("Submit");
					setErrorMsg(data.details);
				}
			} catch (e) {
				toast.error(e.message, {
					position: "bottom-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: true,
					progress: undefined,
					theme: "colored",
				});
				setErrorMsg("empty");
				props.onHide(false);
				setSubmitButton("Submit");
			}
		} else {
			setErrorMsg("Invalid location name or admin details");
			setSubmitButton("Submit");
			if (
				itemName === "empty" ||
				itemName === "" ||
				itemColor === "empty" ||
				itemColor === "" ||
				itemQuantity === "empty" ||
				itemSizeX === "empty" ||
				itemSizeY === "empty"
			) {
				setErrorMsg("All fields are required");
			}
		}
	}

	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			onExiting={() => {
				setErrorMsg("empty");
				setSubmitButton("Submit");
				setItemName("empty");
				setItemColor("empty");
				setItemQuantity("empty");
				setItemSizeX("empty");
				setItemSizeY("empty");
			}}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					{props.title}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<FloatingLabel label="Name" htmlFor="itemName">
					<Form.Control
						type="text"
						className="form-control mb-3"
						id="itemName"
						required
						onChange={validateItemName}
					/>
				</FloatingLabel>
				{itemName === "empty" || itemName === true ? (
					""
				) : (
					<Alert key="itemName" variant="danger">
						Enter a name for the item
					</Alert>
				)}
				<FloatingLabel label="Color" htmlFor="itemColor">
					<Form.Control
						type="text"
						className="form-control mb-3"
						id="itemColor"
						required
						onChange={validateColorName}
					/>
				</FloatingLabel>
				{itemColor === "empty" || itemColor === true ? (
					""
				) : (
					<Alert key="color" variant="danger">
						Enter a color for the item
					</Alert>
				)}
				<FloatingLabel label="Quantity" htmlFor="itemQuantity">
					<Form.Control
						type="number"
						className="form-control mb-3"
						id="itemQuantity"
						required
						onChange={(e) => {
							const value = e.target.value;
							if (value > 0) {
								setItemQuantity(value);
							} else {
								e.target.value = 0;
							}
						}}
					/>
				</FloatingLabel>
				<FloatingLabel label="Size in axis X (cm)" htmlFor="itemSizeX">
					<Form.Control
						type="number"
						className="form-control mb-3"
						id="itemSizeX"
						required
						onChange={(e) => {
							const value = e.target.value;
							if (value > 0) {
								setItemSizeX(value);
							} else {
								e.target.value = 0;
							}
						}}
					/>
				</FloatingLabel>
				<FloatingLabel label="Size in axis Y (cm)" htmlFor="itemSizeY">
					<Form.Control
						type="number"
						className="form-control mb-3"
						id="itemSizeY"
						required
						onChange={(e) => {
							const value = e.target.value;
							if (value > 0) {
								setItemSizeY(value);
							} else {
								e.target.value = 0;
							}
						}}
					/>
				</FloatingLabel>
				{errorMsg === "empty" || errorMsg === true ? (
					""
				) : (
					<Alert key="email" variant="danger">
						{errorMsg}
					</Alert>
				)}
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant="primary"
					onClick={ApiAddItem}
					disabled={submitButton != "Submit"}
				>
					{submitButton}
				</Button>
				<Button variant="secondary" onClick={props.onHide}>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

AddItem.propTypes = {
	onHide: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
};

export default AddItem;
