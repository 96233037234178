import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Alert } from "react-bootstrap";
import log from "../utils/logger";
import { getConfig } from "../../config/config";
import PropTypes from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";

function DeleteLocation(props) {
	const { getAccessTokenSilently } = useAuth0();

	const apiUrl = getConfig().apiUrl;

	let [errorMsg, setErrorMsg] = useState("empty");
	let [submitButton, setSubmitButton] = useState("Submit");

	const id = props.id;

	async function ApiDeleteLocation() {
		setSubmitButton(
			<div className="spinner-border spinner-border-sm" role="status">
				<span className="sr-only"></span>
			</div>,
		);

		try {
			const response = await fetch(apiUrl + "/locations", {
				method: "DELETE",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					Authorization: "Bearer " + (await getAccessTokenSilently()),
				},
				body: JSON.stringify({
					id: id,
				}),
			});

			if (response.ok) {
				toast.success(
					"Location deleted successfully, you may now refresh the page",
					{
						position: "bottom-right",
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: false,
						draggable: true,
						progress: undefined,
						theme: "colored",
					},
				);
				log("Location deleted successfully.");
				props.onHide(false);
				setSubmitButton("Submit");
			} else {
				const data = await response.json();
				toast.error(data.details, {
					position: "bottom-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: true,
					progress: undefined,
					theme: "colored",
				});
				log(data.details);
				setSubmitButton("Submit");
				setErrorMsg(data.details);
			}
		} catch (e) {
			toast.error(e.message, {
				position: "bottom-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: true,
				progress: undefined,
				theme: "colored",
			});
			log(e.message);
			props.onHide(false);
			setSubmitButton("Submit");
		}
	}

	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			onExiting={() => {
				setSubmitButton("Submit");
			}}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					{props.title}
				</Modal.Title>
			</Modal.Header>
			{errorMsg === "empty" || errorMsg === true ? (
				""
			) : (
				<Modal.Body>
					<Alert key="email" variant="danger">
						{errorMsg}
					</Alert>
				</Modal.Body>
			)}
			<Modal.Footer>
				<Button
					variant="primary"
					onClick={ApiDeleteLocation}
					disabled={submitButton != "Submit"}
				>
					{submitButton}
				</Button>
				<Button variant="secondary" onClick={props.onHide}>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

DeleteLocation.propTypes = {
	onHide: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
};

export default DeleteLocation;
