import React from "react";

const notFoundPage = () => {

	return (
		<div>
            Page not found
		</div>
	);
};

export default notFoundPage;