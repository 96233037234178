import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { OptionCreator } from "../components";
import { getConfig } from "../config/config";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Profile = () => {
	const { user } = useAuth0();
	const { name, picture, email } = user;

	const config = getConfig();

	let options = [];
	options.push(
		<OptionCreator
			title={"Update profile picture"}
			description={"Change current profile picture"}
			action={"picture"}
			icon={"image"}
			key={"picture"}
		/>,
	);
	options.push(
		<OptionCreator
			title={"Update email"}
			description={"Change current email"}
			action={"email"}
			icon={"envelope"}
			key={"email"}
		/>,
	);
	options.push(
		<OptionCreator
			title={"Update password"}
			description={"Change current password"}
			action={"passwd"}
			icon={"key"}
			key={"passwd"}
		/>,
	);

	return (
		<div>
			<div className="profile-header mt-4">
				<h2>Profile</h2>
			</div>
			<div className="row align-items-center profile-header mt-4">
				<div className="col-md-2 mb-3">
					<img
						src={picture}
						alt="Profile"
						className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
					/>
				</div>
				<div className="col-md text-md-left">
					<h2>{name}</h2>
					<p className="lead text-muted">{email}</p>
				</div>
			</div>
			<div className="row g-4 pb-5 pt-3 row-cols-1 row-cols-lg-3">
				{options}
			</div>
			{config.debug === "true" ? (
				<pre className="col-12 text-light bg-dark p-4">
					{JSON.stringify(user, null, 2)}
				</pre>
			) : (
				""
			)}
			<ToastContainer
				position="bottom-right"
				autoClose={3000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				theme="colored"
			/>
		</div>
	);
};

export default Profile;
