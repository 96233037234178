import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Route, Routes } from "react-router-dom";

import {
	Home,
	Profile,
	Scan,
	Inventory,
	InventoryAdmin,
	NotFoundPage,
	Organizations,
	Users,
	Locations,
} from "./views";
import { NavBar, Footer, Loading } from "./components";
import AuthenticationGuard from "./components/auth/authentication-guard";

import "./App.css";

const App = () => {
	const { isLoading } = useAuth0();

	if (isLoading) {
		return <Loading />;
	}

	return (
		<div>
			<NavBar />
			<div className="container flex-grow-1">
				<Routes>
					<Route path="/" exact Component={Home} />
					<Route
						path="/profile"
						element={<AuthenticationGuard component={Profile} />}
					/>
					<Route
						path="/orgs"
						element={
							<AuthenticationGuard component={Organizations} />
						}
					/>
					<Route
						path="/users"
						element={<AuthenticationGuard component={Users} />}
					/>
					<Route
						path="/locations"
						element={<AuthenticationGuard component={Locations} />}
					/>
					<Route
						path="/scan"
						element={<AuthenticationGuard component={Scan} />}
					/>
					<Route
						path="/inventories"
						element={
							<AuthenticationGuard component={InventoryAdmin} />
						}
					/>
					<Route
						path="/inventory"
						element={<AuthenticationGuard component={Inventory} />}
					/>
					<Route
						path="/error"
						element={
							<AuthenticationGuard component={NotFoundPage} />
						}
					/>
				</Routes>
			</div>
			<Footer />
		</div>
	);
};

export default App;
