import { getConfig } from "../../config/config";

function log(props) {
	const config = getConfig();
	if (config.debug === "true") {
		console.log(props);
	}
}

export default log;
