import React, { useState, useCallback, useEffect } from "react";
import log from "../components/utils/logger";
import { OptionCreator, ActionCreator } from "../components";
import { getConfig } from "../config/config";
import { useAuth0 } from "@auth0/auth0-react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function inventory() {
	const { user, getAccessTokenSilently } = useAuth0();

	const [inventories, setInventories] = useState([]);
	const [locationAdmin, setLocationAdmin] = useState(false);

	const apiUrl = getConfig().apiUrl;

	const getInventories = useCallback(async () => {
		try {
			const response = await fetch(apiUrl + "/inventory/admin", {
				method: "GET",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					Authorization: "Bearer " + (await getAccessTokenSilently()),
					"X-Admin": user.sub.replace("auth0|", ""),
				},
			});

			if (response.ok) {
				log("User role fetched successfully.");
				const data = await response.json();
				setLocationAdmin(data.content);
			} else {
				const data = await response.json();
				toast.error("Error fetching user role. " + data.details, {
					position: "bottom-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: true,
					progress: undefined,
					theme: "colored",
				});
				log("Error fetching user role. " + data.details);
			}

			const response_inventory = await fetch(apiUrl + "/inventory", {
				method: "GET",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					Authorization: "Bearer " + (await getAccessTokenSilently()),
					"X-Admin": user.sub.replace("auth0|", ""),
				},
			});

			if (response_inventory.ok) {
				log("Inventories fetched successfully.");
				const data = await response_inventory.json();
				setInventories(data.content);
			} else {
				const data = await response_inventory.json();
				toast.error("Error fetching inventories. " + data.details, {
					position: "bottom-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: true,
					progress: undefined,
					theme: "colored",
				});
				log("Error fetching inventories. " + data.details);
			}
		} catch (e) {
			toast.error("Error fetching inventories. " + e.message, {
				position: "bottom-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: true,
				progress: undefined,
				theme: "colored",
			});
			console.log(e.message);
		}
	}, [apiUrl]);

	useEffect(() => {
		getInventories();
	}, [getInventories]);

	return (
		<div>
			<div className="profile-header mt-4">
				<h2>
					<button
						className="refresh-button"
						onClick={() => getInventories()}
					>
						<i className="bi bi-arrow-clockwise"></i>
					</button>
					Inventory
				</h2>
			</div>
			<div className="row g-4 pb-5 pt-3 row-cols-1 row-cols-lg">
				{locationAdmin ? (
					<OptionCreator
						title={"Add item"}
						description={"Add a new item to the inventory"}
						action={"add-item"}
						icon={"plus-circle"}
						btnMessage={"Add"}
						key={"add-item"}
					/>
				) : (
					""
				)}
			</div>
			<div className="table-responsive">
				<table className="table table-striped" id="inventories">
					<thead>
						<tr>
							<th>Name</th>
							<th>Colors</th>
							<th>Availability</th>
							<th>Size X (cm)</th>
							<th>Size Y (cm)</th>
							{locationAdmin ? <th>Actions</th> : ""}
						</tr>
					</thead>
					<tbody>
						{inventories.map((inventory) => (
							<tr key={inventory.id} id={inventory.id}>
								<td id={inventory.id + "_name"}>
									{inventory.name}
								</td>
								<td id={inventory.id + "_color"}>
									{inventory.color}
								</td>
								<td id={inventory.id + "_availability"}>
									{inventory.available}/{inventory.quantity}
								</td>
								<td id={inventory.id + "_sizeX"}>
									{inventory.sizeX}
								</td>
								<td id={inventory.id + "_sizeY"}>
									{inventory.sizeY}
								</td>
								{locationAdmin ? (
									<td>
										<ActionCreator
											title={"Edit item"}
											action={"edit-item"}
											icon={"pen"}
											key={"edit-item-" + inventory.id}
											id={inventory.id}
										/>
										<ActionCreator
											title={"Delete item"}
											action={"delete-item"}
											icon={"trash"}
											key={"delete-item-" + inventory.id}
											id={inventory.id}
										/>
									</td>
								) : (
									""
								)}
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<ToastContainer
				position="bottom-right"
				autoClose={3000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				theme="colored"
			/>
		</div>
	);
}

export default inventory;
