import React from "react";

import logo from "../../../public/photos/logo.png";

const Hero = () => (
	<div
		className="text-center hero d-flex align-items-center justify-content-center"
		style={{ height: "85vh" }}
	>
		<div>
			<img
				className="mb-3 app-logo"
				src={logo}
				alt="iNNventory"
				width="400"
			/>
			<h1 className="mb-4">iNNventory</h1>
		</div>
	</div>
);

export default Hero;
