import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Alert } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { FloatingLabel } from "react-bootstrap";
import log from "../utils/logger";
import { getConfig } from "../../config/config";
import PropTypes from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";

function EditUser(props) {
	const { getAccessTokenSilently } = useAuth0();

	const apiUrl = getConfig().apiUrl;

	let [errorMsg, setErrorMsg] = useState("empty");
	let [emailMatches, setEmailMatches] = useState("empty");
	let [name, setName] = useState("empty");
	let [submitButton, setSubmitButton] = useState("Submit");

	const id = props.id;

	function validateEmail() {
		let email = document.getElementById("email").value;

		if (email.length === 0) {
			setEmailMatches("empty");
		} else {
			let matches =
				/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,}$/.test(email);

			setEmailMatches(matches);
			if (matches) {
				setErrorMsg("empty");
				setSubmitButton("Submit");
			}
		}
	}

	function validateName() {
		let name = document.getElementById("name").value;

		name.length > 2 ? setName(true) : setName(false);

		name.length === 0 ? setName("empty") : setName(true);
	}

	async function ApiEditUser() {
		setSubmitButton(
			<div className="spinner-border spinner-border-sm" role="status">
				<span className="sr-only"></span>
			</div>,
		);

		if (emailMatches === "empty" && name === "empty") {
			setErrorMsg(
				"You must enter at least a name or a valid email address to edit the user",
			);
			setSubmitButton("Submit");
		} else if (
			emailMatches === "empty" ||
			name === "empty" ||
			(emailMatches && name)
		) {
			try {
				let email;

				if (emailMatches === "empty") {
					email = document.getElementById(id + "_email").innerText;
				} else {
					email = document.getElementById("email").value;
				}

				if (name === "empty") {
					name = document.getElementById(id + "_name").innerText;
				} else {
					name = document.getElementById("name").value;
				}

				const response = await fetch(apiUrl + "/users", {
					method: "PATCH",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
						Authorization:
							"Bearer " + (await getAccessTokenSilently()),
					},
					body: JSON.stringify({
						id: id,
						name: name,
						email: email,
					}),
				});

				if (response.ok) {
					toast.success(
						"User edited successfully, you may now refresh the page",
						{
							position: "bottom-right",
							autoClose: 3000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: false,
							draggable: true,
							progress: undefined,
							theme: "colored",
						},
					);
					log("User edited successfully.");
					setName("empty");
					setEmailMatches("empty");
					setErrorMsg("empty");
					props.onHide(false);
					setSubmitButton("Submit");
				} else {
					const data = await response.json();
					toast.error(data.details, {
						position: "bottom-right",
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: false,
						draggable: true,
						progress: undefined,
						theme: "colored",
					});
					log(data.details);
					setSubmitButton("Submit");
					setErrorMsg(data.details);
				}
			} catch (e) {
				toast.error(e.message, {
					position: "bottom-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: true,
					progress: undefined,
					theme: "colored",
				});
				log(e.message);
				setName("empty");
				setEmailMatches("empty");
				setErrorMsg("empty");
				props.onHide(false);
				setSubmitButton("Submit");
			}
		} else {
			log("Invalid email or name");
		}
	}

	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			onExiting={() => {
				setName("empty");
				setEmailMatches("empty");
				setErrorMsg("empty");
				setSubmitButton("Submit");
			}}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					{props.title}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<FloatingLabel label="Name" htmlFor="name">
					<Form.Control
						type="text"
						className="form-control mb-3"
						id="name"
						required
						onChange={validateName}
					/>
				</FloatingLabel>
				{name === "empty" || name === true ? (
					""
				) : (
					<Alert key="name" variant="danger">
						Enter a name for the user
					</Alert>
				)}
				<FloatingLabel label="Email" htmlFor="email">
					<Form.Control
						type="email"
						className="form-control mb-3"
						id="email"
						required
						onChange={validateEmail}
					/>
				</FloatingLabel>
				{emailMatches === "empty" || emailMatches === true ? (
					""
				) : (
					<Alert key="email" variant="danger">
						Invalid email format
					</Alert>
				)}
				{errorMsg === "empty" || errorMsg === true ? (
					""
				) : (
					<Alert key="email" variant="danger">
						{errorMsg}
					</Alert>
				)}
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant="primary"
					onClick={ApiEditUser}
					disabled={submitButton != "Submit"}
				>
					{submitButton}
				</Button>
				<Button variant="secondary" onClick={props.onHide}>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

EditUser.propTypes = {
	onHide: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
};

export default EditUser;
