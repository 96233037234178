import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { FloatingLabel } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { Alert } from "react-bootstrap";
import log from "../utils/logger";
import { getConfig } from "../../config/config";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

function ChangePicture(props) {
	const apiUrl = getConfig().apiUrl;

	const { user, getAccessTokenSilently } = useAuth0();

	let [uploadSelector, setUploadSelector] = useState();
	let [uploadElement, setUploadElement] = useState();
	let [uploadValid, setUploadValid] = useState("empty");
	let [errorMsg, setErrorMsg] = useState("empty");
	let [submitButton, setSubmitButton] = useState("Submit");

	function validateImage(image) {
		if (image.size > getConfig().maxUploadSize) {
			setUploadValid(false);
		} else {
			let matches = /(\.jpg|\.jpeg|\.png|\.gif)$/.test(image.name);
			setUploadValid(matches);
			if (matches) {
				setErrorMsg("empty");
				setSubmitButton("Submit");
			}
		}
	}

	async function ApiChangePicture() {
		setSubmitButton(
			<div className="spinner-border spinner-border-sm" role="status">
				<span className="sr-only"></span>
			</div>,
		);

		if (uploadSelector === "url") {
			try {
				const response = await fetch(
					apiUrl + "/users/update-profile-picture/url",
					{
						method: "PATCH",
						headers: {
							Accept: "application/json",
							"Content-Type": "application/json",
							Authorization:
								"Bearer " + (await getAccessTokenSilently()),
						},
						body: JSON.stringify({
							userId: user.sub,
							pictureUrl: uploadElement,
						}),
					},
				);

				if (response.ok) {
					toast.success(
						"Profile picture edited successfully, you may now refresh the page",
						{
							position: "bottom-right",
							autoClose: 3000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: false,
							draggable: true,
							progress: undefined,
							theme: "colored",
						},
					);
					log("Profile picture edited successfully");
					setErrorMsg("empty");
					props.onHide(false);
					setSubmitButton("Submit");
				} else {
					const data = await response.json();
					toast.error(data.details, {
						position: "bottom-right",
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: false,
						draggable: true,
						progress: undefined,
						theme: "colored",
					});
					log(data.details);
					setSubmitButton("Submit");
					setErrorMsg(data.details);
				}
			} catch (e) {
				toast.error(e.message, {
					position: "bottom-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: true,
					progress: undefined,
					theme: "colored",
				});
				console.log(e);
				props.onHide(false);
				setSubmitButton("Submit");
			}
		} else if (uploadSelector === "upload") {
			if (uploadValid === true) {
				try {
					const formData = new FormData();
					formData.append("userId", user.sub);
					formData.append("fileToUpload", uploadElement);

					const response = await fetch(
						apiUrl + "/users/update-profile-picture/file",
						{
							method: "PATCH",
							headers: {
								Accept: "application/json",
								Authorization:
									"Bearer " +
									(await getAccessTokenSilently()),
							},
							body: formData,
						},
					);

					if (response.ok) {
						log("Profile picture edited successfully");
						setErrorMsg("empty");
						props.onHide(false);
						setSubmitButton("Submit");
					} else {
						const data = await response.json();
						log(data.details);
						setSubmitButton("Submit");
						setErrorMsg(data.details);
					}
				} catch (e) {
					console.log(e.message);
					props.onHide(false);
					setSubmitButton("Submit");
				}
			} else if (!uploadValid) {
				setErrorMsg("Invalid image format or size");
				setSubmitButton("Submit");
			} else if (uploadValid === "empty") {
				setErrorMsg("Select an image to upload");
				setSubmitButton("Submit");
			}
		} else {
			setErrorMsg("Select one of the update methods");
			setSubmitButton("Submit");
		}
	}

	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			backdrop="static"
			keyboard={true}
			onExiting={() => {
				setUploadSelector("none");
				setErrorMsg("empty");
				setSubmitButton("Submit");
			}}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					{props.title}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<label>Profile picture update method</label>
				<Form.Select
					id="imageSelector"
					className="mt-2"
					onChange={() => {
						setErrorMsg("empty");
						var select = document.getElementById("imageSelector");
						var value = select.options[select.selectedIndex].value;
						setUploadSelector(value);
					}}
					defaultValue="none"
				>
					<option value="none"></option>
					<option value="url">Image URL</option>
					<option value="upload">Image upload</option>
				</Form.Select>
				{uploadSelector === "url" ? (
					<FloatingLabel
						htmlFor="imgURL"
						label="Image URL"
						className="mt-3"
					>
						<Form.Control
							type="text"
							id="imgURL"
							onChange={(e) => setUploadElement(e.target.value)}
							required
						/>
					</FloatingLabel>
				) : (
					""
				)}
				{uploadSelector === "upload" ? (
					<Form.Group htmlFor="imgUpload" className="mt-3">
						<Form.Control
							type="file"
							id="imgUpload"
							onChange={(e) => {
								if (e.target.files) {
									setUploadElement(e.target.files[0]);
									validateImage(e.target.files[0]);
								}
							}}
							accept="image/*"
							name="fileUpload"
							required
						/>
					</Form.Group>
				) : (
					""
				)}
				{uploadSelector === "none" ? "" : ""}
				{errorMsg === "empty" || errorMsg === true ? (
					""
				) : (
					<Alert key="email" variant="danger">
						{errorMsg}
					</Alert>
				)}
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant="primary"
					onClick={() => {
						ApiChangePicture();
					}}
					disabled={submitButton != "Submit"}
				>
					{submitButton}
				</Button>
				<Button
					variant="secondary"
					onClick={() => {
						props.onHide(false);
						setUploadSelector("none");
					}}
				>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

ChangePicture.propTypes = {
	title: PropTypes.string,
	onHide: PropTypes.func,
};

export default ChangePicture;
