import React, { useState, useCallback, useEffect } from "react";
import log from "../components/utils/logger";
import { OptionCreator, ActionCreator } from "../components";
import { getConfig } from "../config/config";
import { useAuth0 } from "@auth0/auth0-react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function organizations() {
	const { getAccessTokenSilently } = useAuth0();

	const [orgs, setOrgs] = useState([]);

	const apiUrl = getConfig().apiUrl;

	const getOrgs = useCallback(async () => {
		try {
			const response = await fetch(apiUrl + "/orgs", {
				method: "GET",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					Authorization: "Bearer " + (await getAccessTokenSilently()),
				},
			});

			if (response.ok) {
				log("Organizations fetched successfully.");
				const data = await response.json();
				setOrgs(data.content);
			} else {
				const data = await response.json();
				toast.error("Error fetching organizations. " + data.details, {
					position: "bottom-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: true,
					progress: undefined,
					theme: "colored",
				});
				log("Error fetching organizations. " + data.details);
			}
		} catch (e) {
			toast.error("Error fetching organizations. " + e.message, {
				position: "bottom-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: true,
				progress: undefined,
				theme: "colored",
			});
			console.log(e.message);
		}
	}, [apiUrl]);

	useEffect(() => {
		getOrgs();
	}, [getOrgs]);

	return (
		<div>
			<div className="profile-header mt-4">
				<h2>
					<button
						className="refresh-button"
						onClick={() => getOrgs()}
					>
						<i className="bi bi-arrow-clockwise"></i>
					</button>
					Organizations
				</h2>
			</div>
			<div className="row g-4 pb-5 pt-3 row-cols-1 row-cols-lg">
				<OptionCreator
					title={"Create organization"}
					description={"Create a new organization"}
					action={"add-org"}
					icon={"building-add"}
					btnMessage={"Add"}
					key={"add-org"}
				/>
			</div>
			<div className="table-responsive">
				<table className="table table-striped" id="orgs">
					<thead>
						<tr>
							<th>Organization name</th>
							<th>Administrator</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						{orgs.map((org) => (
							<tr key={org.id} id={org.id}>
								<td id={org.id + "_name"}>{org.name}</td>
								<td id={org.id + "_admin"}>{org.user.name}</td>
								<td>
									<ActionCreator
										title={"Edit organization"}
										action={"edit-org"}
										icon={"pen"}
										key={"edit-org-" + org.id}
										id={org.id}
									/>
									<ActionCreator
										title={"Delete organization"}
										action={"delete-org"}
										icon={"trash"}
										key={"delete-org-" + org.id}
										id={org.id}
									/>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<ToastContainer
				position="bottom-right"
				autoClose={3000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				theme="colored"
			/>
		</div>
	);
}

export default organizations;
