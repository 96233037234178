import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Alert } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { FloatingLabel } from "react-bootstrap";
import log from "../utils/logger";
import { getConfig } from "../../config/config";
import PropTypes from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";

function EditOrg(props) {
	const { getAccessTokenSilently } = useAuth0();

	const apiUrl = getConfig().apiUrl;

	let [errorMsg, setErrorMsg] = useState("empty");
	let [name, setName] = useState("empty");
	let [orgName, setOrgName] = useState("empty");
	let [emailValid, setEmailValid] = useState("empty");
	let [selectedAdmin, setSelectedAdmin] = useState("empty");
	let [submitButton, setSubmitButton] = useState("Submit");

	const id = props.id;

	function validateOrgName() {
		let name = document.getElementById("orgName").value;

		name.length > 2 ? setOrgName(true) : setOrgName(false);

		name.length === 0 ? setOrgName("empty") : setOrgName(true);
	}

	function validateName() {
		let adminName = document.getElementById("name").value;

		adminName.length === 0 ? setName("empty") : setName(false);

		adminName.length > 2 ? setName(true) : setName(false);
		console.log(name);
	}

	function validateEmail() {
		let email = document.getElementById("email").value;

		if (email.length === 0) {
			setEmailValid(false);
		} else {
			let matches =
				/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,}$/.test(email);

			setEmailValid(matches);
			if (matches) {
				setErrorMsg("empty");
				setSubmitButton("Submit");
			}
		}
	}

	async function ApiEditOrg() {
		setSubmitButton(
			<div className="spinner-border spinner-border-sm" role="status">
				<span className="sr-only"></span>
			</div>,
		);

		if (selectedAdmin === false && name === "empty") {
			setErrorMsg(
				"You must enter at least a name or a valid email address to edit the organization",
			);
			setSubmitButton("Submit");
		} else if (
			orgName !== "empty" ||
			(selectedAdmin && name && emailValid)
		) {
			try {
				let newOrgName, adminName, adminEmail;

				if (orgName === "empty") {
					newOrgName = document.getElementById(
						id + "_name",
					).innerText;
				} else {
					newOrgName = document.getElementById("orgName").value;
				}

				if (selectedAdmin !== "empty" && selectedAdmin !== false) {
					adminName = document.getElementById("name").value;
					adminEmail = document.getElementById("email").value;
				} else {
					adminName = "";
					adminEmail = "";
				}

				const response = await fetch(apiUrl + "/orgs", {
					method: "PATCH",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
						Authorization:
							"Bearer " + (await getAccessTokenSilently()),
					},
					body: JSON.stringify({
						id: id,
						orgName: newOrgName,
						adminName: adminName,
						adminEmail: adminEmail,
					}),
				});

				if (response.ok) {
					toast.success(
						"Organization edited successfully, you may now refresh the page",
						{
							position: "bottom-right",
							autoClose: 3000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: false,
							draggable: true,
							progress: undefined,
							theme: "colored",
						},
					);
					log("Organization edited successfully.");
					setName("empty");
					setErrorMsg("empty");
					props.onHide(false);
					setSubmitButton("Submit");
					setSelectedAdmin("empty");
					setOrgName("empty");
					setEmailValid("empty");
				} else {
					const data = await response.json();
					toast.error(data.details, {
						position: "bottom-right",
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: false,
						draggable: true,
						progress: undefined,
						theme: "colored",
					});
					log(data.details);
					setSubmitButton("Submit");
					setErrorMsg(data.details);
					setSelectedAdmin("empty");
					setName("empty");
					setEmailValid("empty");
					setOrgName("empty");
				}
			} catch (e) {
				toast.error(e.message, {
					position: "bottom-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: true,
					progress: undefined,
					theme: "colored",
				});
				log(e.message);
				setErrorMsg("empty");
				props.onHide(false);
				setSubmitButton("Submit");
			}
		} else {
			setSubmitButton("Submit");
			log("Invalid email or name");
			if (orgName === "empty") {
				setErrorMsg("Organization name cannot be empty");
			}
			if (name === "empty") {
				setErrorMsg("Administrator's name cannot be empty");
			}
			if (emailValid === "empty") {
				setErrorMsg("Administrator's email cannot be empty");
			}
			if (emailValid === false && name === false) {
				setErrorMsg("Administrator's email and name cannot be empty");
			}
			if (emailValid === false && name === true) {
				setErrorMsg("Administrator's email cannot be empty");
			}
			if (emailValid === true && name === false) {
				setErrorMsg("Administrator's name cannot be empty");
			}
		}
	}

	const handleEditSelect = (event) => {
		if (event.target.value === "empty") {
			setSelectedAdmin(false);
			setName(false);
			setEmailValid(false);
		} else {
			setSelectedAdmin(true);
		}
	};

	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			onExiting={() => {
				setErrorMsg("empty");
				setName("empty");
				setEmailValid("empty");
				setOrgName("empty");
				setSelectedAdmin("empty");
				setSubmitButton("Submit");
			}}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					{props.title}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<FloatingLabel label="Organization name" htmlFor="orgName">
					<Form.Control
						type="text"
						className="form-control mb-3"
						id="orgName"
						required
						onChange={validateOrgName}
					/>
				</FloatingLabel>
				{orgName === "empty" || orgName === true ? (
					""
				) : (
					<Alert key="name" variant="danger">
						Enter a name for the organization
					</Alert>
				)}
				<FloatingLabel label="Administrator" htmlFor="editSelect">
					<Form.Select
						className="form-control mb-3"
						id="editSelect"
						required
						onChange={handleEditSelect}
					>
						<option value="empty" key="empty"></option>
						<option value="create" key="create">
							Create a new administrator
						</option>
					</Form.Select>
				</FloatingLabel>
				{selectedAdmin === "empty" || selectedAdmin === false ? (
					""
				) : (
					<>
						<FloatingLabel
							label="Administrator's name"
							htmlFor="name"
						>
							<Form.Control
								type="text"
								className="form-control mb-3"
								id="name"
								required
								onChange={validateName}
							/>
						</FloatingLabel>
						{name === "empty" || name === true ? (
							""
						) : (
							<Alert key="name" variant="danger">
								Enter a name for the administrator
							</Alert>
						)}
						<FloatingLabel
							label="Administrator's email"
							htmlFor="email"
						>
							<Form.Control
								type="text"
								className="form-control mb-3"
								id="email"
								required
								onChange={validateEmail}
							/>
						</FloatingLabel>
						{emailValid === "empty" || emailValid === true ? (
							""
						) : (
							<Alert key="name" variant="danger">
								Enter a valid email for the user
							</Alert>
						)}
					</>
				)}
				{errorMsg === "empty" || errorMsg === true ? (
					""
				) : (
					<Alert key="email" variant="danger">
						{errorMsg}
					</Alert>
				)}
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant="primary"
					onClick={ApiEditOrg}
					disabled={submitButton != "Submit"}
				>
					{submitButton}
				</Button>
				<Button variant="secondary" onClick={props.onHide}>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

EditOrg.propTypes = {
	onHide: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
};

export default EditOrg;
