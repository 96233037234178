import React, { useState } from "react";
import { Button } from "react-bootstrap";
import "../../App.css";
import { DeleteOrg, EditOrg } from "../orgs";
import { DeleteUser, EditUser } from "../users";
import { DeleteLocation, EditLocation } from "../locations";
import { DeleteItem, EditItem } from "../inventory";
import PropTypes from "prop-types";

function ActionCreator(props) {
	const [modalShow, setModalShow] = useState(false);

	let option;
	const title = props.title;
	const action = props.action;
	const icon = "bi bi-" + props.icon;
	const id = props.id;

	if (action === "edit-org") {
		option = (
			<EditOrg
				show={modalShow}
				onHide={() => setModalShow(false)}
				title={title}
				key={action}
				id={id}
			/>
		);
	} else if (action === "delete-org") {
		option = (
			<DeleteOrg
				show={modalShow}
				onHide={() => setModalShow(false)}
				title={title}
				key={action}
				id={id}
			/>
		);
	} else if (action === "edit-user") {
		option = (
			<EditUser
				show={modalShow}
				onHide={() => setModalShow(false)}
				title={title}
				key={action}
				id={id}
			/>
		);
	} else if (action === "delete-user") {
		option = (
			<DeleteUser
				show={modalShow}
				onHide={() => setModalShow(false)}
				title={title}
				key={action}
				id={id}
			/>
		);
	} else if (action === "edit-loc") {
		option = (
			<EditLocation
				show={modalShow}
				onHide={() => setModalShow(false)}
				title={title}
				key={action}
				id={id}
			/>
		);
	} else if (action === "delete-loc") {
		option = (
			<DeleteLocation
				show={modalShow}
				onHide={() => setModalShow(false)}
				title={title}
				key={action}
				id={id}
			/>
		);
	} else if (action === "edit-item") {
		option = (
			<EditItem
				show={modalShow}
				onHide={() => setModalShow(false)}
				title={title}
				key={action}
				id={id}
			/>
		);
	} else if (action === "delete-item") {
		option = (
			<DeleteItem
				show={modalShow}
				onHide={() => setModalShow(false)}
				title={title}
				key={action}
				id={id}
			/>
		);
	}

	return (
		<Button className="action-button" onClick={() => setModalShow(true)}>
			<i className={icon}></i>
			{option}
		</Button>
	);
}

ActionCreator.propTypes = {
	title: PropTypes.string.isRequired,
	action: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
};

export default ActionCreator;
