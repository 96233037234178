import React, { useState, useCallback, useEffect } from "react";
import log from "../components/utils/logger";
import { getConfig } from "../config/config";
import { useAuth0 } from "@auth0/auth0-react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function inventoryAdmin() {
	const { user, getAccessTokenSilently } = useAuth0();

	const [inventories, setInventories] = useState([]);
	const [title, setTitle] = useState("empty");

	const apiUrl = getConfig().apiUrl;

	const getInventories = useCallback(async () => {
		try {
			setTitle("Organization inventories");

			const response = await fetch(apiUrl + "/inventory/all", {
				method: "GET",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					Authorization: "Bearer " + (await getAccessTokenSilently()),
					"X-Admin": user.sub.replace("auth0|", ""),
				},
			});

			if (response.ok) {
				log("Inventories fetched successfully.");
				const data = await response.json();
				setInventories(data.content);
			} else {
				const data = await response.json();
				toast.error("Error fetching inventories. " + data.details, {
					position: "bottom-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: true,
					progress: undefined,
					theme: "colored",
				});
				log("Error fetching inventories. " + data.details);
			}
		} catch (e) {
			toast.error("Error fetching inventories. " + e.message, {
				position: "bottom-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: true,
				progress: undefined,
				theme: "colored",
			});
			console.log(e.message);
		}
	}, [apiUrl]);

	useEffect(() => {
		getInventories();
	}, [getInventories]);

	return (
		<div>
			<div className="profile-header mt-4">
				<h2>
					<button
						className="refresh-button"
						onClick={() => getInventories()}
					>
						<i className="bi bi-arrow-clockwise"></i>
					</button>
					{title}
				</h2>
			</div>
			<div className="table-responsive">
				<table className="table table-striped" id="inventories">
					<thead>
						<tr>
							<th>Location</th>
							<th>Name</th>
							<th>Colors</th>
							<th>Availability</th>
							<th>Size X (cm)</th>
							<th>Size Y (cm)</th>
						</tr>
					</thead>
					<tbody>
						{inventories.map((inventory) => (
							<tr key={inventory.id} id={inventory.id}>
								<td id={inventory.id + "_locationName"}>
									{inventory.location.locName}
								</td>
								<td id={inventory.id + "_name"}>
									{inventory.name}
								</td>
								<td id={inventory.id + "_color"}>
									{inventory.color}
								</td>
								<td id={inventory.id + "_availability"}>
									{inventory.available}/{inventory.quantity}
								</td>
								<td id={inventory.id + "_sizeX"}>
									{inventory.sizeX}
								</td>
								<td id={inventory.id + "_sizeY"}>
									{inventory.sizeY}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<ToastContainer
				position="bottom-right"
				autoClose={3000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				theme="colored"
			/>
		</div>
	);
}

export default inventoryAdmin;
