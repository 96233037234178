import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

import NavBarLink from "./navbarlink";

import "../../App.css";
import log from "../utils/logger";

const MainNav = () => {
	let options = [];
	const { isAuthenticated, user } = useAuth0();

	if (isAuthenticated) {
		var role = user["user_type"];
		log("Role: " + role);
		if (role === "superadmin") {
			options.push(
				<NavBarLink
					link={"orgs"}
					icon={"building"}
					text={"Orgs"}
					key={"Orgs"}
				/>,
			);
			options.push(
				<NavBarLink
					link={"users"}
					icon={"people"}
					text={"Users"}
					key={"Users"}
				/>,
			);
		} else if (role === "admin") {
			options.push(
				<NavBarLink
					link={"locations"}
					icon={"houses"}
					text={"Locations"}
					key={"Locations"}
				/>,
			);
			options.push(
				<NavBarLink
					link={"users"}
					icon={"people"}
					text={"Users"}
					key={"Users"}
				/>,
			);
			options.push(
				<NavBarLink
					link={"inventories"}
					icon={"tools"}
					text={"Inventory"}
					key={"Inventory"}
				/>,
			);
		} else if (role === "user") {
			options.push(
				<NavBarLink
					link={"scan"}
					icon={"upc-scan"}
					text={"Scan"}
					key={"Scan"}
				/>,
			);
			options.push(
				<NavBarLink
					link={"inventory"}
					icon={"tools"}
					text={"Inventory"}
					key={"Inventory"}
				/>,
			);
		}

		options.push(
			<NavBarLink
				link={"profile"}
				icon={"person"}
				text={"Profile"}
				key={"Profile"}
			/>,
		);
	}

	return (
		<ul className="nav col-12 col-lg-auto my-2 justify-content-center my-md-0 text-small">
			<NavBarLink link={""} icon={"house"} text={"Home"} key={"Home"} />
			{options}
		</ul>
	);
};

export default MainNav;
