import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useAuth0 } from "@auth0/auth0-react";
import Form from "react-bootstrap/Form";
import { Alert } from "react-bootstrap";
import { FloatingLabel } from "react-bootstrap";
import log from "../utils/logger";
import { getConfig } from "../../config/config";
import { toast } from "react-toastify";

function ChangePasswd(props) {
	const apiUrl = getConfig().apiUrl;

	const { user, getAccessTokenSilently } = useAuth0();

	let [errorMsg, setErrorMsg] = useState("empty");
	let [passwdValid, setPasswdValid] = useState("empty");
	let [passwdMatches, setPasswdMatches] = useState("empty");
	let [submitButton, setSubmitButton] = useState("Submit");

	function validateData() {
		let passwd = document.getElementById("passwdFirst").value;
		let passwdRepeat = document.getElementById("passwdRepeat").value;

		passwd.normalize() === passwdRepeat.normalize()
			? setPasswdMatches(true)
			: setPasswdMatches(false);
	}

	function validatePasswd() {
		let passwd = document.getElementById("passwdFirst").value;

		if (passwd.length === 0) {
			setPasswdValid(false);
		} else {
			let matches =
				/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(
					passwd,
				);

			setPasswdValid(matches);
			if (matches) {
				setErrorMsg("empty");
				setSubmitButton("Submit");
			}
		}
	}

	async function ApiChangePasswd() {
		setSubmitButton(
			<div className="spinner-border spinner-border-sm" role="status">
				<span className="sr-only"></span>
			</div>,
		);

		if (passwdValid && passwdMatches) {
			try {
				let passwd = document.getElementById("passwdFirst").value;

				const response = await fetch(
					apiUrl + "/users/update-password",
					{
						method: "PATCH",
						headers: {
							Accept: "application/json",
							"Content-Type": "application/json",
							Authorization:
								"Bearer " + (await getAccessTokenSilently()),
						},
						body: JSON.stringify({
							password: passwd,
							userId: user.sub,
						}),
					},
				);

				if (response.ok) {
					toast.success(
						"Password changed successfully, you may now refresh the page",
						{
							position: "bottom-right",
							autoClose: 3000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: false,
							draggable: true,
							progress: undefined,
							theme: "colored",
						},
					);
					log("Password changed successfully.");
					setErrorMsg("empty");
					props.onHide(false);
					setSubmitButton("Submit");
				} else {
					const data = await response.json();
					toast.error(data.details, {
						position: "bottom-right",
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: false,
						draggable: true,
						progress: undefined,
						theme: "colored",
					});
					log(data.details);
					setSubmitButton("Submit");
					setErrorMsg(data.details);
				}
			} catch (e) {
				toast.error(e.message, {
					position: "bottom-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: true,
					progress: undefined,
					theme: "colored",
				});
				console.log(e.message);
				props.onHide(false);
				setSubmitButton("Submit");
			}
		} else {
			setErrorMsg("Passwords dont match or are invalid");
			setSubmitButton("Submit");
		}
	}

	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			onExiting={() => {
				setErrorMsg("empty");
				setPasswdValid("empty");
				setPasswdMatches("empty");
				setSubmitButton("Submit");
			}}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					{props.title}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<FloatingLabel label="New password" htmlFor="passwdFirst">
					<Form.Control
						type="password"
						className="form-control mb-3"
						id="passwdFirst"
						onChange={validatePasswd}
						required
					/>
				</FloatingLabel>
				<FloatingLabel
					label="Repeat new password"
					htmlFor="passwdRepeat"
				>
					<Form.Control
						type="password"
						className="form-control mb-3"
						id="passwdRepeat"
						onChange={validateData}
						required
					/>
				</FloatingLabel>
				{passwdMatches === "empty" || passwdMatches === true ? (
					""
				) : (
					<Alert key="email" variant="danger">
						Passwords don&apos;t match
					</Alert>
				)}
				{passwdValid === "empty" || passwdValid === true ? (
					""
				) : (
					<Alert key="email" variant="danger">
						Password doesn&apos;t match requirements (special
						characters (!@#$%^&*), Lower case (a-z), upper case
						(A-Z), numbers (0-9) and at least 8 digits)
					</Alert>
				)}
				{errorMsg === "empty" || errorMsg === true ? (
					""
				) : (
					<Alert key="email" variant="danger">
						{errorMsg}
					</Alert>
				)}
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant="primary"
					onClick={ApiChangePasswd}
					disabled={submitButton != "Submit"}
				>
					{submitButton}
				</Button>
				<Button variant="secondary" onClick={props.onHide}>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

ChangePasswd.propTypes = {
	onHide: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
};

export default ChangePasswd;
