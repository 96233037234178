import React from "react";
import PropTypes from "prop-types"; // Added prop-types import
import history from "../utils/history";
import { Auth0Provider } from "@auth0/auth0-react";
import { getConfig } from "../../config/config";

const Auth0ProviderWithHistory = ({ children }) => {
	const onRedirectCallback = (appState) => {
		history.push(
			appState && appState.returnTo
				? appState.returnTo
				: window.location.pathname,
		);
	};

	const config = getConfig();

	const providerConfig = {
		domain: config.domain,
		clientId: config.clientId,
		onRedirectCallback,
		authorizationParams: {
			redirect_uri: window.location.origin,
			...(config.audience ? { audience: config.audience } : null),
		},
		...(config.debug === "true" ? { cacheLocation: "localstorage" } : null),
	};

	return <Auth0Provider {...providerConfig}>{children}</Auth0Provider>;
};

Auth0ProviderWithHistory.propTypes = {
	children: PropTypes.node.isRequired, // Added prop-types validation for children
};

export default Auth0ProviderWithHistory;
