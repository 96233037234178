import React from "react";

import logo from "../../../public/photos/logo.png";

const Footer = () => (
	<footer className="container d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top position-absolute bottom-0 start-0 end-0">
		<div className="col-md-8 d-flex align-items-center">
			<img
				src={logo}
				height="32"
				alt="iNNventory"
				className="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1"
			></img>
			<span className="text-muted">
				© {new Date().getFullYear()} Markel Álvarez Martínez
			</span>
		</div>

		<ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
			<a
				target="_blank"
				rel="noopener noreferrer"
				href="https://markel.eus"
				className="ms-3"
			>
				<i className="bi bi-globe"></i>
			</a>
			{/* <a target="_blank" rel="noopener noreferrer" href="https://github.com/MarkelAlvarez" className="ms-3"><i className="bi bi-github"></i></a> */}
		</ul>
	</footer>
);

export default Footer;
