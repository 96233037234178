import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useAuth0 } from "@auth0/auth0-react";
import Form from "react-bootstrap/Form";
import { Alert } from "react-bootstrap";
import { FloatingLabel } from "react-bootstrap";
import log from "../utils/logger";
import { getConfig } from "../../config/config";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

function ChangeEmail(props) {
	const apiUrl = getConfig().apiUrl;

	const { user, getAccessTokenSilently } = useAuth0();

	let [errorMsg, setErrorMsg] = useState("empty");
	let [emailValid, setEmailValid] = useState("empty");
	let [emailMatches, setEmailMatches] = useState("empty");
	let [submitButton, setSubmitButton] = useState("Submit");

	function validateData() {
		let email = document.getElementById("emailFirst").value;
		let emailRepeat = document.getElementById("emailRepeat").value;

		email.normalize() === emailRepeat.normalize()
			? setEmailMatches(true)
			: setEmailMatches(false);
	}

	function validateEmail() {
		let email = document.getElementById("emailFirst").value;

		if (email.length === 0) {
			setEmailValid(false);
		} else {
			let matches =
				/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,}$/.test(email);

			setEmailValid(matches);
			if (matches) {
				setErrorMsg("empty");
				setSubmitButton("Submit");
			}
		}
	}

	const ApiChangeMail = async () => {
		setSubmitButton(
			<div className="spinner-border spinner-border-sm" role="status">
				<span className="sr-only"></span>
			</div>,
		);

		if (emailValid && emailMatches) {
			try {
				let email = document.getElementById("emailFirst").value;

				const response = await fetch(apiUrl + "/users/update-email", {
					method: "PATCH",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
						Authorization:
							"Bearer " + (await getAccessTokenSilently()),
					},
					body: JSON.stringify({
						email: email,
						userId: user.sub,
					}),
				});

				if (response.ok) {
					toast.success(
						"Email edited successfully, you may now refresh the page",
						{
							position: "bottom-right",
							autoClose: 3000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: false,
							draggable: true,
							progress: undefined,
							theme: "colored",
						},
					);
					log("Email edited successfully.");
					setErrorMsg("empty");
					props.onHide(false);
					setSubmitButton("Submit");
				} else {
					const data = await response.json();
					toast.error(data.details, {
						position: "bottom-right",
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: false,
						draggable: true,
						progress: undefined,
						theme: "colored",
					});
					log(data.details);
					setSubmitButton("Submit");
					setErrorMsg(data.details);
				}
			} catch (e) {
				toast.error(e.message, {
					position: "bottom-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: true,
					progress: undefined,
					theme: "colored",
				});
				console.log(e.message);
				props.onHide(false);
				setSubmitButton("Submit");
			}
		} else {
			setErrorMsg("Emails don't match or have an invalid email format");
			setSubmitButton("Submit");
		}
	};

	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			onExiting={() => {
				setErrorMsg("empty"),
				setEmailValid("empty"),
				setEmailMatches("empty"),
				setSubmitButton("Submit");
			}}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					{props.title}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<FloatingLabel label="New email address" htmlFor="emailFirst">
					<Form.Control
						type="email"
						className="form-control mb-3"
						id="emailFirst"
						onChange={validateEmail}
						required
					/>
				</FloatingLabel>
				<FloatingLabel
					label="Repeat new email address"
					htmlFor="emailRepeat"
				>
					<Form.Control
						type="email"
						className="form-control mb-3"
						id="emailRepeat"
						onChange={validateData}
						required
					/>
				</FloatingLabel>
				{emailMatches === "empty" || emailMatches === true ? (
					""
				) : (
					<Alert key="email" variant="danger">
						Emails don&apos;t match
					</Alert>
				)}
				{emailValid === "empty" || emailValid === true ? (
					""
				) : (
					<Alert key="email" variant="danger">
						Invalid email format
					</Alert>
				)}
				{errorMsg === "empty" || errorMsg === true ? (
					""
				) : (
					<Alert key="error" variant="danger">
						{errorMsg}
					</Alert>
				)}
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant="primary"
					onClick={ApiChangeMail}
					disabled={submitButton != "Submit"}
				>
					{submitButton}
				</Button>
				<Button variant="secondary" onClick={props.onHide}>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

ChangeEmail.propTypes = {
	title: PropTypes.string, // Added prop type validation for 'title'
	onHide: PropTypes.func, // Added prop type validation for 'onHide'
};

export default ChangeEmail;
