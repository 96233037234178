import React from "react";
import { css } from "@emotion/react";
import GridLoader from "react-spinners/GridLoader";

import '../../App.css'

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  margin-top: 20%;
  border-color: black;
`;

class Loading extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true
		};
	}

	render() {
		return (
			<div className="spinner d-flex justify-content-center align-items-center" style={{ height: '100vh', width: '100vw', backgroundColor: 'white' }}>
				<GridLoader css={override} size={50} color={"#212529"} loading={this.state.loading} speedMultiplier={1} />
			</div>
		);
	}
}

export default Loading;