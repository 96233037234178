import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Alert } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { FloatingLabel } from "react-bootstrap";
import log from "../utils/logger";
import { getConfig } from "../../config/config";
import PropTypes from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";

function AddUser(props) {
	const { user, getAccessTokenSilently } = useAuth0();

	const apiUrl = getConfig().apiUrl;

	let [errorMsg, setErrorMsg] = useState("empty");
	let [userName, setName] = useState("empty");
	let [locations, setLocations] = useState([]);
	let [selectedLocation, setSelectedLocation] = useState("empty");
	let [emailValid, setEmailValid] = useState("empty");
	let [submitButton, setSubmitButton] = useState("Submit");

	function validateName() {
		let name = document.getElementById("name").value;

		name.length > 2 ? setName(true) : setName(false);
	}

	function validateEmail() {
		let email = document.getElementById("email").value;

		if (email.length === 0) {
			setEmailValid(false);
		} else {
			let matches =
				/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,}$/.test(email);

			setEmailValid(matches);
			if (matches) {
				setErrorMsg("empty");
				setSubmitButton("Submit");
			}
		}
	}

	async function getLocations() {
		try {
			const response = await fetch(apiUrl + "/locations", {
				method: "GET",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					Authorization: "Bearer " + (await getAccessTokenSilently()),
					"X-Admin": user.sub.replace("auth0|", ""),
				},
			});

			if (response.ok) {
				log("Locations fetched successfully.");
				const data = await response.json();
				setLocations(data.content);
				data.content.length === 0
					? setErrorMsg("Create a location before adding a user")
					: setErrorMsg("empty");
			} else {
				const data = await response.json();
				toast.error("Error fetching locations. " + data.details, {
					position: "bottom-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: true,
					progress: undefined,
					theme: "colored",
				});
				log("Error fetching locations. " + data.details);
				setErrorMsg(data.details);
			}
		} catch (e) {
			toast.error(e.message, {
				position: "bottom-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: true,
				progress: undefined,
				theme: "colored",
			});
			console.log(e.message);
		}
	}

	async function ApiAddUser() {
		setSubmitButton(
			<div className="spinner-border spinner-border-sm" role="status">
				<span className="sr-only"></span>
			</div>,
		);

		const userRole = user["user_type"];
		let role;

		if (userRole === "superadmin") {
			role = getConfig().adminRoleId;
		} else if (userRole === "admin") {
			role = getConfig().userRoleId;
		}

		if (
			selectedLocation === "empty" ||
			userName === "empty" ||
			emailValid === "empty"
		) {
			setErrorMsg(
				"You must enter a name, email and select a location to add a user",
			);
			setSubmitButton("Submit");
		} else if (
			userName !== "empty" &&
			emailValid !== "empty" &&
			selectedLocation !== "empty"
		) {
			try {
				const name = document.getElementById("name").value;
				const email = document.getElementById("email").value;
				const response = await fetch(apiUrl + "/users", {
					method: "POST",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
						Authorization:
							"Bearer " + (await getAccessTokenSilently()),
					},
					body: JSON.stringify({
						creator: user.sub.replace("auth0|", ""),
						name: name,
						email: email,
						role: role,
						locationId: selectedLocation,
					}),
				});

				if (response.ok) {
					toast.success(
						"User added successfully, you may now refresh the page",
						{
							position: "bottom-right",
							autoClose: 3000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: false,
							draggable: true,
							progress: undefined,
							theme: "colored",
						},
					);
					log("User added successfully.");
					setName("empty");
					setErrorMsg("empty");
					setSelectedLocation("empty");
					setLocations([]);
					setEmailValid("empty");
					props.onHide(false);
					setSubmitButton("Submit");
				} else {
					const data = await response.json();
					toast.error(data.details, {
						position: "bottom-right",
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: false,
						draggable: true,
						progress: undefined,
						theme: "colored",
					});
					log(data.details);
					setSubmitButton("Submit");
					setErrorMsg(data.details);
				}
			} catch (e) {
				toast.error(e.message, {
					position: "bottom-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: true,
					progress: undefined,
					theme: "colored",
				});
				console.log(e.message);
				setName("empty");
				setErrorMsg("empty");
				setEmailValid("empty");
				setSelectedLocation("empty");
				setLocations([]);
				props.onHide(false);
				setSubmitButton("Submit");
			}
		} else {
			if (selectedLocation === "empty") {
				setSubmitButton("Submit");
				setErrorMsg("Please select a location");
			}
			log("Invalid user name or email");
			setSubmitButton("Submit");
		}
	}

	const handleLocationSelect = (event) => {
		if (event.target.value === "empty") {
			setSelectedLocation("empty");
		} else {
			setErrorMsg("empty");
			setSelectedLocation(event.target.value);
		}
	};

	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			onEnter={getLocations}
			onExiting={() => {
				setName("empty");
				setErrorMsg("empty");
				setSelectedLocation("empty");
				setLocations([]);
				setEmailValid("empty");
				setSubmitButton("Submit");
			}}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					{props.title}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<FloatingLabel label="Name" htmlFor="name">
					<Form.Control
						type="text"
						className="form-control mb-3"
						id="name"
						required
						onChange={validateName}
						disabled={locations.length === 0}
					/>
				</FloatingLabel>
				{userName === "empty" || userName === true ? (
					""
				) : (
					<Alert key="name" variant="danger">
						Enter a name for the user
					</Alert>
				)}
				<FloatingLabel label="Email" htmlFor="email">
					<Form.Control
						type="email"
						className="form-control mb-3"
						id="email"
						required
						onChange={validateEmail}
						disabled={locations.length === 0}
					/>
				</FloatingLabel>
				{emailValid === "empty" || emailValid === true ? (
					""
				) : (
					<Alert key="email" variant="danger">
						Enter an email for the user
					</Alert>
				)}
				<FloatingLabel label="Location" htmlFor="editSelect">
					<Form.Select
						className="form-control mb-3"
						id="editSelect"
						required
						onEnter={handleLocationSelect}
						onChange={handleLocationSelect}
						disabled={locations.length === 0}
					>
						{locations.length === 0 ? (
							<option value="empty" key="empty">
								No locations available
							</option>
						) : (
							<>
								<option value="empty" key="empty"></option>
								{locations.map((location) => (
									<option
										value={location.id}
										key={location.id}
									>
										{location.name}
									</option>
								))}
							</>
						)}
					</Form.Select>
				</FloatingLabel>
				{errorMsg === "empty" || errorMsg === true ? (
					""
				) : (
					<Alert key="email" variant="danger">
						{errorMsg}
					</Alert>
				)}
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant="primary"
					onClick={ApiAddUser}
					disabled={
						submitButton != "Submit" || locations.length === 0
					}
				>
					{submitButton}
				</Button>
				<Button variant="secondary" onClick={props.onHide}>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

AddUser.propTypes = {
	onHide: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
};

export default AddUser;
