import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Alert } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { FloatingLabel } from "react-bootstrap";
import log from "../utils/logger";
import { getConfig } from "../../config/config";
import PropTypes from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";

function EditLocation(props) {
	const { user, getAccessTokenSilently } = useAuth0();

	const apiUrl = getConfig().apiUrl;

	let [errorMsg, setErrorMsg] = useState("empty");
	let [locationName, setLocationName] = useState("empty");
	let [users, setUsers] = useState([]);
	let [selectedUser, setSelectedUser] = useState("empty");
	let [submitButton, setSubmitButton] = useState("Submit");

	const id = props.id;

	function validateLocationName() {
		let name = document.getElementById("locationName").value;

		name.length > 2 ? setLocationName(true) : setLocationName(false);

		name.length === 0 ? setLocationName("empty") : setLocationName(true);
	}

	async function getUsers() {
		try {
			const response = await fetch(apiUrl + "/users/no-location", {
				method: "GET",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					Authorization: "Bearer " + (await getAccessTokenSilently()),
					"X-Admin": user.sub.replace("auth0|", ""),
				},
			});

			if (response.ok) {
				log("Unassigned users fetched successfully.");
				const data = await response.json();
				setUsers(data.content);
			} else {
				const data = await response.json();
				toast.error(
					"Error fetching unassigned users. " + data.details,
					{
						position: "bottom-right",
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: false,
						draggable: true,
						progress: undefined,
						theme: "colored",
					},
				);
				log("Error fetching unassigned users. " + data.details);
				setErrorMsg(data.details);
			}
		} catch (e) {
			toast.error(e.message, {
				position: "bottom-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: true,
				progress: undefined,
				theme: "colored",
			});
			console.log(e.message);
		}
	}

	async function ApiEditLocation() {
		setSubmitButton(
			<div className="spinner-border spinner-border-sm" role="status">
				<span className="sr-only"></span>
			</div>,
		);

		if (selectedUser === false && locationName === "empty") {
			setErrorMsg(
				"You must enter at least a name or a valid email address to edit the location",
			);
			setSubmitButton("Submit");
		} else if (locationName || selectedUser) {
			try {
				let newLocationName = "";
				if (locationName === "empty") {
					console.log(id);
					newLocationName = document.getElementById(
						id + "_name",
					).innerText;
				} else {
					newLocationName =
						document.getElementById("locationName").value;
				}

				const response = await fetch(apiUrl + "/locations", {
					method: "PATCH",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
						Authorization:
							"Bearer " + (await getAccessTokenSilently()),
					},
					body: JSON.stringify({
						id: id,
						locationName: newLocationName,
						admin: selectedUser,
					}),
				});

				if (response.ok) {
					toast.success(
						"Location added successfully, you may now refresh the page",
						{
							position: "bottom-right",
							autoClose: 3000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: false,
							draggable: true,
							progress: undefined,
							theme: "colored",
						},
					);
					log("Location added successfully.");
					setErrorMsg("empty");
					setSelectedUser("empty");
					props.onHide(false);
					setSubmitButton("Submit");
				} else {
					const data = await response.json();
					toast.error(data.details, {
						position: "bottom-right",
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: false,
						draggable: true,
						progress: undefined,
						theme: "colored",
					});
					log(data.details);
					setSubmitButton("Submit");
					setErrorMsg(data.details);
					setSelectedUser("empty");
				}
			} catch (e) {
				toast.error(e.message, {
					position: "bottom-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: true,
					progress: undefined,
					theme: "colored",
				});
				setErrorMsg("empty");
				setSelectedUser("empty");
				props.onHide(false);
				setSubmitButton("Submit");
			}
		} else {
			if (selectedUser === "empty") {
				setSubmitButton("Submit");
				setErrorMsg("Please select a user");
			}
			log("Invalid location name");
		}
	}

	const handleEditSelect = (event) => {
		if (event.target.value === "empty") {
			setSelectedUser("empty");
		} else {
			setErrorMsg("empty");
			setSelectedUser(event.target.value);
		}
	};

	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			onEnter={getUsers}
			onExiting={() => {
				setErrorMsg("empty");
				setLocationName("empty");
				setUsers([]);
				setSelectedUser("empty");
				setSubmitButton("Submit");
			}}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					{props.title}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<FloatingLabel label="Location name" htmlFor="locationName">
					<Form.Control
						type="text"
						className="form-control mb-3"
						id="locationName"
						required
						onChange={validateLocationName}
					/>
				</FloatingLabel>
				{locationName === "empty" || locationName === true ? (
					""
				) : (
					<Alert key="name" variant="danger">
						Enter a name for the location
					</Alert>
				)}
				<FloatingLabel label="Administrator" htmlFor="editSelect">
					<Form.Select
						className="form-control mb-3"
						id="editSelect"
						required
						onEnter={handleEditSelect}
						onChange={handleEditSelect}
						disabled={users.length === 0}
					>
						{users.length === 0 ? (
							<option value="empty" key="empty">
								No users available
							</option>
						) : (
							<>
								<option value="empty" key="empty"></option>
								{users.map((user) => (
									<option value={user.id} key={user.id}>
										{user.name} ({user.email})
									</option>
								))}
							</>
						)}
					</Form.Select>
				</FloatingLabel>
				{errorMsg === "empty" || errorMsg === true ? (
					""
				) : (
					<Alert key="email" variant="danger">
						{errorMsg}
					</Alert>
				)}
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant="primary"
					onClick={ApiEditLocation}
					disabled={submitButton != "Submit"}
				>
					{submitButton}
				</Button>
				<Button variant="secondary" onClick={props.onHide}>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

EditLocation.propTypes = {
	onHide: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
};

export default EditLocation;
