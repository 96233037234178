import React from "react";
import { NavLink } from "react-router-dom";

function NavBarLink(props) {
	const link = "/" + props.link;
	const icon = "bi bi-" + props.icon + " d-block";
	const text = props.text;

	return (
		<li>
			<NavLink
				to={link}
				className="nav-link text-white"
			>
				<i className={icon}></i>
				{text}
			</NavLink>
		</li>
	);
}

export default NavBarLink;