import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Alert } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { FloatingLabel } from "react-bootstrap";
import log from "../utils/logger";
import { getConfig } from "../../config/config";
import PropTypes from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";

function AddLocation(props) {
	const { user, getAccessTokenSilently } = useAuth0();

	const apiUrl = getConfig().apiUrl;

	let [errorMsg, setErrorMsg] = useState("empty");
	let [name, setName] = useState("empty");
	let [locName, setLocName] = useState("empty");
	let [emailValid, setEmailValid] = useState("empty");
	let [submitButton, setSubmitButton] = useState("Submit");

	function validateLocName() {
		let name = document.getElementById("locName").value;

		name.length > 2 ? setLocName(true) : setLocName(false);

		name.length === 0 ? setLocName("empty") : setLocName(true);
	}

	function validateName() {
		let name = document.getElementById("name").value;

		name.length > 2 ? setName(true) : setName(false);

		name.length === 0 ? setName("empty") : setName(true);
	}

	function validateEmail() {
		let email = document.getElementById("email").value;

		if (email.length === 0) {
			setEmailValid(false);
		} else {
			let matches =
				/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,}$/.test(email);

			setEmailValid(matches);
			if (matches) {
				setErrorMsg("empty");
				setSubmitButton("Submit");
			}
		}
	}

	async function ApiAddLocation() {
		setSubmitButton(
			<div className="spinner-border spinner-border-sm" role="status">
				<span className="sr-only"></span>
			</div>,
		);

		if (name !== "empty" && locName !== "empty" && emailValid !== "empty") {
			try {
				const locName = document.getElementById("locName").value;
				const name = document.getElementById("name").value;
				const email = document.getElementById("email").value;
				const response = await fetch(apiUrl + "/locations", {
					method: "POST",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
						Authorization:
							"Bearer " + (await getAccessTokenSilently()),
					},
					body: JSON.stringify({
						creator: user.sub.replace("auth0|", ""),
						locationName: locName,
						name: name,
						email: email,
						role: getConfig().userRoleId,
					}),
				});

				if (response.ok) {
					toast.success(
						"Location added successfully, you may now refresh the page",
						{
							position: "bottom-right",
							autoClose: 3000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: false,
							draggable: true,
							progress: undefined,
							theme: "colored",
						},
					);
					log("Location added successfully.");
					setErrorMsg("empty");
					setName("empty");
					setEmailValid("empty");
					setLocName("empty");
					props.onHide(false);
					setSubmitButton("Submit");
				} else {
					const data = await response.json();
					toast.error(data.details, {
						position: "bottom-right",
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: false,
						draggable: true,
						progress: undefined,
						theme: "colored",
					});
					log(data.details);
					setSubmitButton("Submit");
					setErrorMsg(data.details);
				}
			} catch (e) {
				toast.error(e.message, {
					position: "bottom-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: true,
					progress: undefined,
					theme: "colored",
				});
				setErrorMsg("empty");
				props.onHide(false);
				setSubmitButton("Submit");
			}
		} else {
			setErrorMsg("Invalid location name or admin details");
			setSubmitButton("Submit");
			if (locName === "empty" || locName === "") {
				setErrorMsg("Location name cannot be empty");
			}
			if (name === "empty" || name === "") {
				setErrorMsg("Administrator's name cannot be empty");
			}
			if (emailValid === "empty" || emailValid === "") {
				setErrorMsg("Administrator's email cannot be empty");
			}
			if (locName === "empty" && name === "empty") {
				setErrorMsg(
					"Location name and administrator's name cannot be empty",
				);
			}
			if (locName === "empty" && emailValid === "empty") {
				setErrorMsg(
					"Location name and administrator's email cannot be empty",
				);
			}
			if (name === "empty" && emailValid === "empty") {
				setErrorMsg("Administrator's name and email cannot be empty");
			}
			if (
				locName === "empty" &&
				name === "empty" &&
				emailValid === "empty"
			) {
				setErrorMsg(
					"Location name and administrator's name and email cannot be empty",
				);
			}
		}
	}

	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			onExiting={() => {
				setErrorMsg("empty");
				setName("empty");
				setEmailValid("empty");
				setLocName("empty");
				setSubmitButton("Submit");
			}}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					{props.title}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<FloatingLabel label="Location name" htmlFor="locName">
					<Form.Control
						type="text"
						className="form-control mb-3"
						id="locName"
						required
						onChange={validateLocName}
					/>
				</FloatingLabel>
				{locName === "empty" || locName === true ? (
					""
				) : (
					<Alert key="name" variant="danger">
						Enter a name for the location
					</Alert>
				)}
				<FloatingLabel label="Administrator's name" htmlFor="name">
					<Form.Control
						type="text"
						className="form-control mb-3"
						id="name"
						required
						onChange={validateName}
					/>
				</FloatingLabel>
				{name === "empty" || name === true ? (
					""
				) : (
					<Alert key="name" variant="danger">
						Enter a name for the administrator
					</Alert>
				)}
				<FloatingLabel label="Administrator's email" htmlFor="email">
					<Form.Control
						type="text"
						className="form-control mb-3"
						id="email"
						required
						onChange={validateEmail}
					/>
				</FloatingLabel>
				{emailValid === "empty" || emailValid === true ? (
					""
				) : (
					<Alert key="name" variant="danger">
						Enter a valid email for the user
					</Alert>
				)}
				{errorMsg === "empty" || errorMsg === true ? (
					""
				) : (
					<Alert key="email" variant="danger">
						{errorMsg}
					</Alert>
				)}
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant="primary"
					onClick={ApiAddLocation}
					disabled={submitButton != "Submit"}
				>
					{submitButton}
				</Button>
				<Button variant="secondary" onClick={props.onHide}>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

AddLocation.propTypes = {
	onHide: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
};

export default AddLocation;
