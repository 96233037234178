import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import log from "../components/utils/logger";
// import uploadImageToS3 from "../components/scan/uploadS3";
import { useAuth0 } from "@auth0/auth0-react";
import { FormSelect } from "react-bootstrap";
import Buffer from "buffer";
import { getConfig } from "../config/config";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "../App.css";
import poster from "../../public/photos/fondo_webcam.png";

function scanner() {
	const { getAccessTokenSilently } = useAuth0();
	const [scan, setScan] = useState("retrieve");
	const [playing, setPlaying] = useState(false);
	var cameraSelection;
	let cameraList;

	const apiUrl = getConfig().apiUrl;

	const startVideo = () => {
		if (
			!navigator.mediaDevices ||
			!navigator.mediaDevices.enumerateDevices
		) {
			log("enumerateDevices() not supported.");

			return;
		}

		var constrains = {
			audio: false,
			video: {
				// width: { ideal: 1080 },
				// height: { ideal: 1920 },
				deviceId: cameraSelection,
			},
		};

		navigator.mediaDevices
			.getUserMedia(constrains)
			.then(function (mediaStream) {
				setPlaying(false);
				setPlaying(true);

				var video = document.querySelector("video");
				video.srcObject = mediaStream;
				video.onloadedmetadata = function () {
					video.play();
				};
				setPlaying(true);
			})
			.catch(function (err) {
				//TODO: toast
				log(err.name + ": " + err.message);
			});
	};

	const stopVideo = () => {
		setPlaying(false);
		let video = document.getElementsByClassName("videoFeed")[0];
		try {
			video.srcObject.getTracks()[0].stop();
		} catch (err) {
			//TODO: toast
			log(err.name + ": no camara connected. " + err.message);
		}
		video.srcObject = null;
	};

	function dataURLtoFile(dataurl, filename) {
		var arr = dataurl.split(",");
		var mime = arr[0].match(/:(.*?);/)[1];
		var bstr = new Buffer.Buffer.from(arr[1], "base64");
		var cstr = bstr.toString("latin1");
		var n = bstr.length;
		var u8arr = new Uint8Array(n);

		while (n--) {
			u8arr[n] = cstr.charCodeAt(n);
		}

		return new File([u8arr], filename, { type: mime });
	}

	const takeScreenshoot = () => {
		var video = document.getElementsByClassName("videoFeed")[0];
		var scale = 1;

		var canvas = document.createElement("canvas");
		canvas.width = video.videoWidth * scale;
		canvas.height = video.videoHeight * scale;
		canvas
			.getContext("2d")
			.drawImage(video, 0, 0, canvas.width, canvas.height);

		var file = dataURLtoFile(
			canvas.toDataURL(),
			"img" + Math.random(5) + ".png",
		);

		// uploadImageToS3(file);
		return file;
	};

	async function scanAndUpload() {
		const file = takeScreenshoot();
		console.log(file);

		try {
			const response = await fetch(apiUrl + "/inventory/scan/" + scan, {
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					Authorization: "Bearer " + (await getAccessTokenSilently()),
				},
				body: JSON.stringify({
					image: file,
				}),
			});

			if (response.ok) {
				toast.success("Image scanned successfully", {
					position: "bottom-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: true,
					progress: undefined,
					theme: "colored",
				});
				log("Image scanned successfully.");
			} else {
				const data = await response.json();
				toast.error(data.details, {
					position: "bottom-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: true,
					progress: undefined,
					theme: "colored",
				});
				log(data.details);
			}
		} catch (e) {
			toast.error(e.message, {
				position: "bottom-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: true,
				progress: undefined,
				theme: "colored",
			});
			console.log(e.message);
		}
	}

	// This can be improved, I'm sure but I so feed up of it not loading on page start that I'll leave it like this
	class CameraSelector extends React.Component {
		static hasLoadedBefore = false;
		componentDidMount() {
			const cameraDropdown = document.getElementById("webcamSelector");

			navigator.mediaDevices
				.enumerateDevices()
				.then(function (devices) {
					if (!CameraSelector.hasLoadedBefore) {
						devices.forEach(function (device) {
							if (device.kind === "videoinput") {
								log(
									device.kind +
										": " +
										device.label +
										" id = " +
										device.deviceId,
								);

								cameraList = document.createElement("option");
								cameraList.text = device.label;
								cameraList.value = device.deviceId;
								cameraList.id = device.deviceId;
								cameraDropdown.add(cameraList);
							}
						});
						CameraSelector.hasLoadedBefore = true;
					}
				})
				.catch(function (err) {
					//TODO: toast
					log(err.name + ": " + err.message);
				});
		}

		componentWillUnmount() {
			stopVideo();
		}

		render() {
			return (
				<div className="container">
					<label>Camera selector</label>
					<FormSelect
						id="webcamSelector"
						className="mt-2"
						onChange={(e) => {
							stopVideo();
							// Need to display selected option on the select to be able to change it
							// var select = document.getElementById('webcamSelector');
							// var option = document.getElementById(e.currentTarget.id);
							// // cameraSelection = select.options[select.selectedIndex].value;
							cameraSelection = e.currentTarget.value;
							// select.value = option.value;
							startVideo();
						}}
					></FormSelect>
				</div>
			);
		}
	}

	return (
		<div>
			<div className="profile-header mt-4">
				<h2>Scanner</h2>
			</div>
			<div className="d-flex justify-content-center mt-3">
				<label className="form-check-label pe-3">Get item</label>
				<Form>
					<Form.Check
						type="switch"
						id="scanSwitch"
						className="form-check-label pe-2"
						onChange={(e) => {
							if (e.currentTarget.checked) {
								setScan("return");
							} else {
								setScan("retrieve");
							}
						}}
					/>
				</Form>
				<label className="form-check-label">Return item</label>
			</div>
			<video
				muted
				autoPlay
				poster={poster}
				className="container videoFeed mt-2 w-50"
			></video>
			<div className="mt-3">
				{playing ? (
					<div>
						<CameraSelector />
						<div className="text-center mt-4">
							<button
								className="btn btn-secondary"
								onClick={stopVideo}
							>
								Stop
							</button>
							<button
								className="btn btn-secondary margin-left"
								onClick={scanAndUpload}
							>
								Take photo
							</button>
						</div>
					</div>
				) : (
					<div className="text-center">
						<button
							className="btn btn-secondary"
							onClick={startVideo}
						>
							Start
						</button>
					</div>
				)}
			</div>
			<ToastContainer
				position="bottom-right"
				autoClose={3000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				theme="colored"
			/>
		</div>
	);
}

export default scanner;
