import React from "react";

import MainNav from "../navbar/main-nav";
import AuthNav from "../navbar/auth-nav";

import logo from '../../../public/photos/logo.png';

const NavBar = () => {
  	return (
		<header className="px-3 py-2 bg-dark text-white">
			<div className="container">
				<div className="d-flex flex-wrap align-items-center justify-content-between">
					<img src={logo} height="45" alt="iNNventory"></img>
					<MainNav />
					<AuthNav />
				</div>
			</div>
		</header>
	);
};

export default NavBar;